<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <!---<v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>

                </v-flex>
            </v-layout>-->

            <!-- telemetry data table -->
            <!--<br>-->
            <telemetry-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></telemetry-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            dataTableColumnsMenuId="toggle-telemetry-list-columns"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
import { isMobileDevice } from '@/helpers/utilities.js'
import { addExportToQueue } from '@/helpers/services.js'
const TelemetryDataTable = () => import('@/components/TelemetryDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {
    components:{
        TelemetryDataTable,
        DataTableToggleColumns
    },


    data: () => ({
        card1: true,
        card2: true,
        card3: true,

        interval1: null,

        cashiers: [],
        selectedCashiers: [],

        dataTableSettings: {
            id: 'dtTelemetry',
            title: 'Cashiers statuses',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                //{ text: "ID", value: "id", class: "text-xs-left", visibility: true },
                { text: "Device", value: "cashier_name", class: "text-xs-left", visibility: true, sortable: false },
                { text: "Alarm", value: "errors_str", class: "text-xs-left", visibility: true, sortable: false },
                { text: "Customer entrances", value: "ecust", class: "text-xs-left", visibility: true, sortable: false  },
                { text: "Staff entrances", value: "epers", class: "text-xs-left", visibility: true , sortable: false },
                { text: "Traffic", value: "coins", class: "text-xs-left", visibility: true , sortable: false },
                { text: "Coin exchanger sum", value: "chng", class: "text-xs-left", visibility: true , sortable: false },
                { text: "CE last empty", value: "oldchng", class: "text-xs-left", visibility: true , sortable: false },
                { text: "Paper level", value: "pfill", class: "text-xs-left", visibility: true , sortable: false },
                { text: "Timestamp", value: "last_update", class: "text-xs-left", visibility: true , sortable: true },
                //{ text: "Actions", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true}
            ],
            updateInterval: true,
            rowsPerPage: 100,
            endpoint: 'ina/eol/telemetry',
            editItem: {
                route: null,
                routeParameterValue: null
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_telemetry',
                totalItems : {
                    event: ''
                },
                search: 'dt-telemetry-search',
                toggleColumnsId: 'toggle-telemetry-list-columns'
            }


        }
    }),

    watch: {

    },

    computed: {
         columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {}
        }
    },

    methods: {
        displayResults() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 280)
        }
    },

    created() {
        var vm = this
        this.$store.dispatch('ROUTE_NAME', this.$route.name)   

        if(isMobileDevice(this)) {
            this.card1 = false
        }
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off(this.dataTableSettings.id)
        EventBus.$off(this.dataTableSettings.eventBus.refreshDataTable)
        EventBus.$off(this.dataTableSettings.eventBus.search)

        this.$store.commit('SET_SEARCH', '');
        this.$store.commit('SET_SEARCH_FIELD_STATE', false);
        this.$store.commit('SET_MOBILE_SEARCH', false)

        clearInterval(this.interval1)
    }


}

</script>

<style scoped>

</style>